import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { PushyState } from "@/store/pushy/types";
import { Pushy_Api } from "@/api/pushy-api";

export const actions: ActionTree<PushyState, RootState> = {
	async fetchSubs({ commit, dispatch }, key) {
		try {
			const response = await Pushy_Api.fetchSubs(key);
			commit("setSubs", response.data.topics);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async send({ dispatch }, data) {
		try {
			data.payload.notification = {
				body: data.payload.data.message,
				badge: 1,
				sound: "ping.aiff"
			};

			const response = await Pushy_Api.send(data.key, {
				...data.payload
			});

			if (response.status === 200) {
				dispatch("notifications/success", "Pushy sent successfully", { root: true });
			}
		} catch (error) {
			//commit("error");
			dispatch("notifications/error", error, { root: true });
		}
	}
};
