import { utils } from "@/api/http-common";
import Axios from "axios";

export class Pushy_Api {
	static async fetchSubs(key: string) {
		return Axios.get(utils.generatePushyUrl(`topics?api_key=${key}`), {
			headers: await utils.generatePushyHeader()
		});
	}

	static async send(key: string, data: any) {
		return Axios.post(
			utils.generatePushyUrl(`push?api_key=${key}`),
			{ ...data },
			{
				headers: await utils.generatePushyHeader()
			}
		);
	}
}
