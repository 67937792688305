import { Module } from "vuex";
import { actions } from "@/store/pushy/actions";
import { mutations } from "@/store/pushy/mutations";
import { RootState } from "@/store/types";
import { PushyState } from "@/store/pushy/types";

export const state: PushyState = {
	subs: []
};

const namespaced: boolean = true;

export const pushy: Module<PushyState, RootState> = {
	namespaced,
	state,
	actions,
	mutations
};
